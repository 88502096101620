@import "./styles/colors";
@import "./styles/fonts";
@import "./styles/typography";
@import "./styles/common";

:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
  --theme: "light";
}

.dark-mode {
  @each $name, $color in $colors-dark {
    --#{$name}: #{$color};
  }
  --theme: "dark";
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: $normal-font-size;
  background-color: getColor(body-color);
  color: getColor(text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  color: getColor(title-color);
  font-weight: $font-semi-bold;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}
