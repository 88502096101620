@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/resolutions";

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: getColor(body-color);
  @include my-screen("tablets") {
    top: 0;
    bottom: initial;
    padding: 0 1rem;
  }
  @include my-screen("laptops") {
    padding: 0;
  }

  &.scrollHeader {
    box-shadow: 0 -1px 4px getColor(shadow-color);
  }

  .nav {
    max-width: 968px;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include my-screen("tablets") {
      height: 3.5rem;
      column-gap: 1rem;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      .changeTheme {
        font-size: 1.25rem;
        color: getColor(title-color);
        margin-right: 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
          color: getColor(primary-color);
        }
      }
    }
    .logo,
    .toggleBtn {
      color: getColor(title-color);
      font-weight: $font-medium;
      &:hover {
        color: getColor(primary-color);
      }
      .appIcon {
        display: block;
        @include my-screen("tablets") {
          display: none;
        }
      }
    }
    .toggleBtn {
      font-size: 1.1rem;
      cursor: pointer;
      @include my-screen("laptops") {
        display: none;
      }
    }
    .menu {
      transition: max-height 0.3s;
      opacity: 0;
      z-index: -2;
      max-height: 0;
      &.showMenu {
        opacity: 1;
        z-index: 100;
        max-height: 500px;
      }
      @media screen and (max-width: 767px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: getColor(body-color);
        box-shadow: 0 -1px 4px getColor(shadow-color);
        border-radius: 4rem 4rem 0 0;
        &.showMenu {
          padding: 2rem 1.5rem 4rem;
        }
      }
      @include my-screen("tablets") {
        margin-left: auto;
        opacity: 1;
        z-index: 100;
        max-height: 500px;
      }
      .list {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        @include my-screen("tablets") {
          display: flex;
          column-gap: 2rem;
        }
        .link {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: $small-font-size;
          font-weight: $font-medium;
          width: 100%;
          color: getColor(title-color);
          &:hover {
            color: getColor(primary-color);
          }
          @include my-screen("tablets") {
            font-size: $normal-font-size;
          }
          .icon {
            @include my-screen("tablets") {
              display: none;
            }
          }
        }
        & .activeLink {
          .link {
            color: getColor(primary-color);
          }
        }
      }
      .close {
        position: absolute;
        right: 1.3rem;
        bottom: 0.5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: getColor(primary-color);
        &:hover {
          color: getColor(primary-color-alt);
        }
        @include my-screen("tablets") {
          display: none;
        }
      }
    }
  }
  .scrollup {
    position: fixed;
    right: 1rem;
    opacity: 0;
    bottom: 3rem;
    background-color: getColor(primary-color);
    padding: 0.3rem;
    border-radius: 0.4rem;
    z-index: 99;
    transition: 0.4s;
    display: flex;
    align-items: center;
    &:hover {
      background-color: getColor(primary-color-alt);
    }
    &.show {
      bottom: 5rem;
      opacity: 0.8;
    }
    .icon {
      color: $color-white;
    }
  }
}
