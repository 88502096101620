@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/resolutions";

.home {
  .container {
    gap: 1rem;
    @include my-screen("tablets") {
      row-gap: 5rem;
    }
  }
  .content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
    @include my-screen("tablets") {
      grid-template-columns: max-content 1fr 1fr;
      padding-top: 5.5rem;
      column-gap: 2rem;
    }
  }
  .social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
    .icon {
      font-size: 1.25rem;
      color: getColor(primary-color);
      &:hover {
        color: getColor(primary-color-alt);
      }
    }
  }
  .image {
    border-radius: 50%;
    width: 200px;
    background: getColor(primary-color);
    border-radius: 50%;
    overflow: hidden;
    height: 210px;
    @include my-screen("tablets") {
      order: 1;
      justify-self: center;
      width: 240px;
      height: 250px;
    }
    @include my-screen("laptops") {
      width: 260px;
      margin-left: 5rem;
      height: 270px;
    }
    img {
      width: 100%;
      position: relative;
      top: 1rem;
    }
  }
  .data {
    grid-column: 1/3;
    @include my-screen("tablets") {
      grid-column: initial;
    }
    .title {
      font-size: $big-font-size;
    }
    .subtitle {
      font-size: $h3-font-size;
      color: getColor(text-color);
      font-weight: $font-medium;
      margin-bottom: 0.75rem;
    }
    .description {
      margin-bottom: 2rem;
    }
  }
  .scrollButton {
    display: none;
    .button {
      color: getColor(primary-color);
      transition: 0.3s;
      &:hover {
        transform: translateY(0.25rem);
      }
      span {
        font-size: $small-font-size;
        color: getColor(title-color);
        font-weight: $font-medium;
        margin-right: 0.25rem;
      }
    }
  }
}
