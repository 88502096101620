@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/resolutions";

.skills {
  .skillsContainer {
    gap: 2.5rem;
    @include my-screen("tablets") {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .content {
    .skillsHeader {
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon,
      .arrow {
        font-size: 2rem;
        color: getColor(primary-color);
      }
      .icon {
        margin-right: 0.75rem;
      }
      .arrow {
        margin-left: auto;
        transition: 0.4s;
      }
      .title {
        font-size: $h3-font-size;
      }
      .subtitle {
        font-size: $small-font-size;
      }
    }
    .list {
      row-gap: 1.5rem;
      padding: 1rem 0 0 2.7rem;
      transition: 0.3s;
      .data {
        .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          .name {
            font-size: $normal-font-size;
            font-weight: $font-medium;
          }
        }
        .bar,
        .percentage {
          height: 5px;
          border-radius: 0.25rem;
        }
        .bar {
          background-color: getColor(primary-color-lighter);
          .percentage {
            display: block;
            background-color: getColor(primary-color);
            &.html {
              width: 90%;
            }
            &.css {
              width: 80%;
            }
            &.javascript {
              width: 85%;
            }
            &.reactjs {
              width: 90%;
            }
            &.angular {
              width: 75%;
            }
            &.vuejs {
              width: 60%;
            }
            &.datastructures {
              width: 85%;
            }
            &.algorihms {
              width: 50%;
            }
            &.git {
              width: 75%;
            }
          }
        }
      }
    }
    &.open {
      .list {
        max-height: 100vh;
        margin-bottom: 2.5rem;
        @include my-screen("tablets") {
          margin-bottom: 0;
        }
      }
      .arrow {
        transform: rotate(-180deg);
      }
    }
    &.close {
      .list {
        max-height: 0;
        overflow: hidden;
        padding-top: 0;
      }
    }
  }
}
