@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/resolutions";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  &.activeModal {
    visibility: visible;
    opacity: 1;
  }
  .modalContent {
    position: relative;
    background-color: getColor(container-color);
    padding: 1.5rem;
    border-radius: 0.5rem;
    @include my-screen("tablets") {
      width: 450px;
    }
    .title {
      font-size: $h3-font-size;
      font-weight: $font-medium;
      margin-bottom: 1rem;
    }
    .modalClose {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 1.5rem;
      color: getColor(primary-color);
      cursor: pointer;
    }
    .modalIcon {
      color: getColor(primary-color);
      margin-right: 0.25rem;
    }
    .servicesOffered {
      row-gap: 1rem;
      .service {
        display: flex;
      }
    }
  }
}
