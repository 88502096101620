$body-font: "poppins";

// font size
$big-font-size: 2rem;
$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1.125rem;
$normal-font-size: 0.938rem;
$small-font-size: 0.813rem;
$smaller-font-size: 0.75rem;

// font weights
$font-medium: 500;
$font-semi-bold: 600;
