@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/resolutions";

.portfolio {
  .portfolioContainer {
    overflow: initial;
    margin: 0 auto;
    .content {
      padding: 0 1.5rem;
      @include my-screen("tablets") {
        grid-template-columns: repeat(2, 1fr);
      }
      @include my-screen("laptops") {
        gap: 5rem;
      }

      .banner {
        width: 265px;
        border-radius: 0.5rem;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid getColor(primary-color);
        @include my-screen("tablets") {
          width: 300px;
          height: 200px;
        }
        p {
          font-size: $h1-font-size;
          color: getColor(primary-color);
        }
      }
      .data {
        @include my-screen("tablets") {
          margin: auto 0;
        }
        .title {
          font-size: $h3-font-size;
          margin-bottom: 0.5rem;
        }
        .description {
          margin-bottom: 0.75rem;
        }
        .portfolioButton {
          &:hover {
            .icon {
              transform: translateX(0.25rem);
            }
          }
        }
      }
    }
    .swiper {
      padding: 0 2rem 2rem;
      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          font-size: 1.5rem;
          color: getColor(primary-color);
          z-index: 20;
          @include my-screen("tablets") {
            font-size: 2rem;
          }
          @include my-screen("laptops") {
            font-size: 2.5rem;
          }
        }
      }
      .swiper-button-prev {
        left: 6px;
        @include my-screen("laptops") {
          left: 0;
        }
      }
      .swiper-button-next {
        right: 6px;
        @include my-screen("laptops") {
          right: 0;
        }
      }
      &.swiper-horizontal {
        & > .swiper-pagination {
          &.swiper-pagination-bullets {
            bottom: 0;
            @include my-screen("tablets") {
              bottom: -6px;
            }
            .swiper-pagination-bullet-active {
              background-color: getColor(primary-color);
            }
          }
        }
      }
    }
  }
}
