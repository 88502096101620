@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/resolutions";

.services {
  .servicesContainer {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    @include my-screen("tablets") {
      grid-template-columns: repeat(2, 230px);
      justify-content: center;
    }
    .content {
      position: relative;
      background-color: getColor(container-color);
      padding: 3.5rem 0.5rem 1.25rem 1.5rem;
      border-radius: 0.25rem;
      box-shadow: 0 2px 4px getColor(shadow-color);
      transition: 0.3s;
      &:hover {
        box-shadow: 0 4px 8px getColor(shadow-color);
      }
      @include my-screen("tablets") {
        padding: 6rem 0 2rem 2.5rem;
      }
      .serviceIcon {
        display: block;
        font-size: 1.5rem;
        color: getColor(primary-color);
        margin-bottom: 1rem;
        @include my-screen("tablets") {
          height: 2rem;
          width: 2rem;
        }
      }
      .title {
        font-size: $h3-font-size;
        margin-bottom: 1rem;
        font-weight: $font-medium;
      }
      .servicesButton {
        cursor: pointer;
        font-size: $small-font-size;
        &:hover {
          .icon {
            transform: translateX(0.25rem);
          }
        }
      }
    }
  }
}
