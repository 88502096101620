@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-medium";
  src: url("../assets/fonts/Poppins-Medium.ttf");
  src: url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
  src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-bold";
  src: url("../assets/fonts/Poppins-Bold.ttf");
  src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
